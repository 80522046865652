import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';

@Injectable()
export class BigIntInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const body = this.convertBigIntToString(req.body);
        const clonedReq = req.clone({ body });
        return next.handle(clonedReq);
    }

    private convertBigIntToString(obj: any): any {
        if (typeof obj === 'bigint') {
            return obj.toString();
        } else if (typeof obj === 'object') {
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    obj[key] = this.convertBigIntToString(obj[key]);
                }
            }
        }
        return obj;
    }
}
