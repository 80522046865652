import { Token } from "./Token";

export interface IUser {
  sub?: number;
  login?: string;
  perimeters?: string[];
  roles?: string[];
  authorizations?: string[];
  accessToken: Token;
  access_token: Token;
}

export interface IUserData {
  id: number;
  name?: string;
  firstname?: string;
  age?: string;
  status?: string;
  isAnonymous?: boolean;
}

export const ANONYMOUS_USER = {
  id: 0,
  name: "Anonymous",
  firstname: "User",
  isAnonymous: true,
} as IUserData;
