import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { CompanyEntity } from 'src/app/ms/hr-ms/model/CompanyEntity';
import { CompanyEntityService } from 'src/app/ms/hr-ms/service/company-entity.service';
import { Mission } from 'src/app/ms/mission-ms/model/Mission';
import { ConsultantService } from 'src/app/ms/mission-ms/service/consultant.service';
import { MissionService } from 'src/app/ms/mission-ms/service/mission.service';
import { FRENCH_LABELED_DATA_TABLE, LANGUAGES } from 'src/app/utils/constants';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-mission-client-contact',
  templateUrl: './mission-client-contact.component.html',
  styleUrls: ['./mission-client-contact.component.scss']
})
export class MissionClientContactComponent implements OnInit {
  companiesCache: Map<bigint, CompanyEntity> = new Map<bigint, CompanyEntity>();
  showView = false;
  mission: any;
  consultant: any;
  companiesArray;
  loading: boolean = true;
  currentUser:any;
  missionsList:any;
  showViewConsultant = false;
  @ViewChild('dt') table: Table;
  statuses: any[];
  totalMissions:number;
  language:any;
  constructor(
    private missionService:MissionService,
    private companyEntityService:CompanyEntityService,
    private translate: TranslateService,
    private consultantService:ConsultantService,
    ) { }

  ngOnInit(): void {
    this.language = localStorage.getItem("language");
    this.loading = false;
    this.currentUser = JSON.parse(localStorage.getItem("user-data"));
    this.statuses = [
      {label: 'En cours', value: 'EN_COURS'},
      {label: 'Suspendue', value: 'SUSPENDUE'},
      {label: 'Annule', value: 'ANNULEE'}
  ]
    this.getMissionsList();
    this.getAllCompaniesEntities();
    
  }

  getMissionsList(){
    this.missionService.getMissionsByClientContactEmail(this.currentUser.email).subscribe((data)=>{
      this.missionsList=data;
      this.totalMissions=this.missionsList.length;
      this.missionsList.forEach((m: Mission, index: number) => {
        let startDate = new Date(m.startDate);
        m.startDate = this.formatMissionDate(startDate);
        let endDate = new Date(m.endDate);
        m.endDate = this.formatMissionDate(endDate);
        
    });
    })
  }

  getStatusText(status: string): string {
    let statusText: string;

    switch (status) {
      case 'EN_COURS':
        statusText = this.translate.instant("TEXT_STATUS_EN_COURS");
        break;
      case 'TERMINEE':
        statusText = this.translate.instant("TEXT_STATUS_FINISHED");
        break;
      case 'SUSPENDUE':
        statusText = this.translate.instant("TEXT_STATUS_SUSPENDUE");
        break;
      default:
        statusText = '';
        break;
    }

    return statusText;
  }

  getDetail(mission) {
    this.mission = mission;
    this.showView = !this.showView;
  }
  getAllCompaniesEntities(){
    this.companyEntityService.getAllCompaniesEntity().subscribe((data)=>{
      this.companiesArray=data;
      this.companiesArray.forEach(elt => {
        this.companiesCache.set(elt.id,elt.label)
      });
    })
    
    }
  RemoveMission(mission) {
    Swal.fire({
      text: this.translate.instant("WARNING_API_DELETE_MISSION"),
      type: "warning",
      showCloseButton: true,
      showCancelButton: true,
    }).then((willDelete) => {
      if (willDelete.dismiss) {
        Swal.fire("", this.translate.instant("TEXT_MISSION_SAFE"), "error");
      } else {
        this.missionService.RemoveMissionById(mission.id).subscribe(
          (response) => {
            Swal.fire(
              "",
              this.translate.instant("SUCCESS_API_DELETE_MISSION"),
              "success"
            ).then((isOk) => {
              if (isOk.dismiss) null;
              else {
                window.location.reload();
              }
            });
          },
          (error) => {
            Swal.fire(
              "",
              this.translate.instant("ERROR_API_DELETE_MISSION"),
              "error"
            );
            
          }
        );
      }
    });
  }

  suspendMission(mission: any) {
    Swal.fire({
      title: this.translate.instant("CONFIRM_SUSPEND_MISSION") + " " + mission.name+" ?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#008B8B',
      confirmButtonText: this.translate.instant("TEXT_CONFIRMATION"),
      cancelButtonText: this.translate.instant("TEXT_CANCELLED"),
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.cancel) {
        return;
      }

      if (result.value) {
        this.missionService.suspendMission(mission.id).subscribe(
          (resp) => {
            Swal.fire({
              text: this.translate.instant("CONFIRM_SUSPEND_MISSION") + " " + mission.name + " ?",
              type: 'warning',
              confirmButtonColor: '#008b8b',
              confirmButtonText: this.translate.instant("TEXT_CONFIRMATION"),
            }).then(() => {
              window.location.reload();
            });
          },
          (err) => {
            Swal.fire({
              text: this.translate.instant("TEXT_ERROR"),
              type: 'error',
              confirmButtonColor: '#008B8B',
              confirmButtonText: this.translate.instant("TEXT_CONFIRM_No_DATA"),
            });
          }
        );
      }
    });
  }
  getDetailConsultant(consultant,mission) {
    this.consultant = consultant;
    this.mission=mission;
    this.showViewConsultant = !this.showViewConsultant;
  }

  RemoveConsultant(consultant) {
    Swal.fire({
      text: this.translate.instant("WARNING_API_DELETE_MISSION"),
      type: "warning",
      showCloseButton: true,
      showCancelButton: true,
    }).then((willDelete) => {
      if (willDelete.dismiss) {
        Swal.fire("", this.translate.instant("TEXT_MISSION_SAFE"), "error");
      } else {
        this.consultantService.removeConsultant(consultant.id).subscribe(
          (response) => {
            Swal.fire(
              "",
              this.translate.instant("SUCCESS_API_DELETE_MISSION"),
              "success"
            ).then((isOk) => {
              if (isOk.dismiss) null;
              else {
                window.location.reload();
              }
            });
          },
          (error) => {
            Swal.fire(
              "",
              this.translate.instant("ERROR_API_DELETE_MISSION"),
              "error"
            );

          }
        );
      }
    });
  }
  onEndDateSelect(event: any) {
    this.table.filter(this.formatMissionDate(event), 'endDate', 'equals');
  }
  
  onStatusChange(event: any) {
    this.table.filter(event.value, 'status.label', 'equals');
  }
  formatDate(date) {
    let month = date.getMonth() + 1;
    let day = date.getDate();
  
    if (month < 10) {
        month = '0' + month;
    }
}
onStartDateSelect(value) {
  this.table.filter(this.formatMissionDate(value), 'startDate', 'equals')
  
}
formatMissionDate(date: Date): string {
  const year = date.getFullYear();
  let month = (date.getMonth() + 1).toString().padStart(2, '0');
  let day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
 /*  this.dtOptions = {
>>>>>>> 3ea08243 (remove all datatabels)
    pagingType: "full_numbers",
    responsive: true,
    search: true,
    columnDefs: [
      { orderable: false, targets: [-1,-2] },
    ],
  }; */

  this.loading = false;
  this.statuses = [
    {label: 'En cours', value: 'EN_COURS'},
    {label: 'Suspendue', value: 'SUSPENDUE'},
    {label: 'Annule', value: 'ANNULEE'}
]
}
}
