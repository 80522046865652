import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ChartsModule, WavesModule } from "angular-bootstrap-md";
import { ClickOutsideModule } from "ng-click-outside";
import { LightboxModule } from "ngx-lightbox";
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from "ngx-perfect-scrollbar";
import { DoughnutChartComponent } from "src/app/ihm/shared/charts/doughnut-chart/doughnut-chart.component";
import { LineChartComponent } from "src/app/ihm/shared/charts/line-chart/line-chart.component";

import {
  AlertModule,
  BreadcrumbModule,
  CardModule,
  ModalModule,
} from "./components";
import { ApexChartComponent } from "./components/chart/apex-chart/apex-chart.component";
import { ApexChartService } from "./components/chart/apex-chart/apex-chart.service";
import { DataFilterPipe } from "./components/data-table/data-filter.pipe";
import { GalleryComponent } from "./components/gallery/gallery.component";
import { SpinnerComponent } from "./components/spinner/spinner.component";
import { ToastComponent } from "./components/toast/toast.component";
import { ToastService } from "./components/toast/toast.service";
import { TodoCardCompleteDirective } from "./components/todo/todo-card-complete.directive";
import { TodoListRemoveDirective } from "./components/todo/todo-list-remove.directive";

/*import 'hammerjs';
import 'mousetrap';
import { GalleryModule } from '@ks89/angular-modal-gallery';*/

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

@NgModule({
  imports: [
    CommonModule,
    PerfectScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    AlertModule,
    CardModule,
    BreadcrumbModule,
    ModalModule,
    ClickOutsideModule,
    LightboxModule,
    WavesModule,
    ChartsModule,
  ],
  exports: [
    CommonModule,
    PerfectScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    AlertModule,
    CardModule,
    BreadcrumbModule,
    ModalModule,
    DataFilterPipe,
    TodoListRemoveDirective,
    TodoCardCompleteDirective,
    ClickOutsideModule,
    SpinnerComponent,
    ApexChartComponent,
    GalleryComponent,
    ToastComponent,
    DoughnutChartComponent,
    LineChartComponent,
  ],
  declarations: [
    DataFilterPipe,
    TodoListRemoveDirective,
    TodoCardCompleteDirective,
    SpinnerComponent,
    ApexChartComponent,
    ToastComponent,
    GalleryComponent,
    DoughnutChartComponent,
    LineChartComponent,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    ApexChartService,
    ToastService,
  ],
})
export class SharedModule {}
