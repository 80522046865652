<div class="q-view" [ngClass]="this.showView ? 'active' : ''">
  <div class="overlay" (click)="event.emit(!this.showView)"></div>
  <div class="content">
    <div class="merge-card">
      <app-card [hidHeader]="true" cardClass="mb-0" blockClass="d-flex align-items-center justify-content-between">
        <h5 class="mb-0">{{"TEXT_UPDATE_MISSION" | translate}}</h5>
        <button type="button" *ngIf="user?.role === 'Company_Admin' || user?.role === 'Manager' || user?.role === 'Client_Contact' " (click)=" this.editMission = !this.editMission;
            this.editMissionIcon = this.editMission ? 'icon-x' : 'icon-edit'
          " class="btn btn-primary btn-sm rounded m-0 float-right" data-toggle="collapse" data-target=".pro-det-edit"
          aria-expanded="false" aria-controls="pro-det-edit-1 pro-det-edit-2">
          <i class="feather" [ngClass]="editMissionIcon"></i>
        </button>
      </app-card>
      <app-card [hidHeader]="true" blockClass="border-top pro-det-edit collapse show">
        <form [formGroup]="frm" (ngSubmit)="update()">
          <div class="form-group row">
            <label class="col-sm-3 offset-sm-1 col-form-label font-weight-bolder">
              {{ "INPUT_MISSION_NAME_LABEL" | translate }}
            </label>
            <div class="col-sm-8">
              <label class="form-control-plaintext" *ngIf="!editMission">
                {{ mission.name }}
              </label>
              <input *ngIf="editMission" type="text" class="form-control" formControlName="name" [(ngModel)]="name"
                placeholder="" value="{{ mission.name }}" />
              <div *ngIf="frm.controls.name.invalid && frm.controls.name.touched" class="text-danger">
                {{"TEXT_VALID_NAME" | translate }}
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 offset-sm-1 col-form-label font-weight-bolder">
              {{"TABLE_HEADER_CLIENT" | translate }}
            </label>
            <div class="col-sm-8">
              <label class="form-control-plaintext">
                {{ mission.client.name}}
              </label>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 offset-sm-1 col-form-label font-weight-bolder">
              {{ "INPUT_START_DATE_LABEL" | translate }}
            </label>
            <div class="col-sm-8">
              <label class="form-control-plaintext" *ngIf="!editMission">
                {{ mission.startDate | date: "dd/MM/yyyy" }}
              </label>
              <input (blur)="compareTwoDates()" *ngIf="editMission" type="date" class="form-control"
       formControlName="startDate" [(ngModel)]="startDate" />
              <div *ngIf="frm.controls.startDate.invalid && frm.controls.startDate.touched" class="text-danger">
                {{"TEXT_SELECT_START_DATE" | translate}}
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-3 offset-sm-1 col-form-label font-weight-bolder">
              {{ "INPUT_END_DATE_LABEL" | translate }}
            </label>
            <div class="col-sm-8">
              <label class="form-control-plaintext" *ngIf="!editMission">
                {{ mission.endDate | date: "dd/MM/yyyy" }}
              </label>
              <input (blur)="compareTwoDates()" *ngIf="editMission" type="date" class="form-control" placeholder=""
                [value]="endDate | date: 'yyyy-MM-dd'" formControlName="endDate" [(ngModel)]="endDate"/>
              <div *ngIf="frm.controls.endDate.invalid && frm.controls.endDate.touched" class="text-danger">
                {{"TEXT_SELECT_END_DATE" | translate}}
              </div>
            </div>
          </div>
          <div class="form-group row" *ngIf="user?.role != 'Client_Contact'">
            <label class="col-sm-3 offset-sm-1 col-form-label font-weight-bolder">
              {{"TABLE_HEADER_MANAGER" | translate}}
            </label>
            <div class="col-sm-8">
              <select class="form-control bold-titles" formControlName="managerId" required
                [(ngModel)]="selectedManager" [attr.disabled]="!editMission ? 'disabled' : null">
                <option *ngFor="let manager of managersMap | keyvalue" [ngValue]="manager.key">{{ manager.value }}
                </option>
              </select>
              <div *ngIf="frm.controls.endDate.invalid && frm.controls.endDate.touched" class="text-danger">
                {{"TEXT_SELECT_MANAGER" | translate}}
              </div>
            </div>
          </div>
          <div class="form-group row" *ngIf="user?.role != 'Client_Contact'">
            <label class="col-sm-3 offset-sm-1 col-form-label font-weight-bolder">
              {{"TEXT_ASSIGNED_MISSION_CONSULTANTS_SUMMARY" | translate}}
            </label>
            <div class="col-sm-8">
              <div class="consultant-row" *ngFor="let consultant of mission.consultants">
                <label class="form-control-plaintext">{{ consultant.firstName }} {{ consultant.lastName }}</label>
                <a disabled="frm.invalid" *ngIf="editMission" (click)="removeConsultant(consultant.id)">
                  <i class="feather icon-trash-2 f-w-600 f-16 text-c-red"></i>
                </a>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <a class="form-control-plaintext" *ngIf="editMission"
                  (click)="showConsultantsSelect = !showConsultantsSelect;">
                  <i class="feather icon-user-plus  rounded text-c-white"
                    style="background-color: #008b8b; padding: 10px; border-radius: 50%;color: white;"></i>
                </a>
                </div>
                <div class="col-sm-8" *ngIf="showConsultantsSelect">
                  <ng-select id="inputConsultant" [items]="consultantsList" [multiple]="true" bindLabel="fullName"
                    bindValue="selectedConsultant" formArrayName="consultants" (change)="addSelectedConsultant($event)">
                    <ng-template ng-label-tmp let-item="item" let-clear="clear">
                      <span class="ng-value-label">{{ item.firstname }} {{ item.lastname }}</span>
                      <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                    </ng-template>
                  </ng-select>
                </div>
              </div>
            </div>
          </div>
          <button [disabled]="frm.invalid" class="btn btn-primary" *ngIf="editMission" type="submit">
            <i class="fa fa-save"></i>
          </button>
        </form>
      </app-card>
    </div>
  </div>
</div>
<ng-http-loader [backgroundColor]="'darkcyan'" [opacity]="0.6" [backdrop]="false"></ng-http-loader>