import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {Token} from "../../oauth2/model/Token";
import {HumanResource} from "../model/HumanResource";

@Injectable({
  providedIn: "root",
})
export class HumanResourceService {
  baseUrl: string = "/hr-ms/api/v1/";
  constructor(private http: HttpClient) { }

  getById(id): Observable<any> {
    return this.http.get(this.baseUrl + "/human-resources/" + id);
  }

  getByEmail(email: string): Observable<any> {
    return this.http.get(
      this.baseUrl + "/human-resources/search-by-email?email=" + email
    );
  }

  emailExists(email: string, token: Token): Observable<any> {
    return this.http.get(
      this.baseUrl + "/human-resources/email-exists?email=" + email,
      {
        headers: {
          Authorization: "Bearer" + token,
        },
      }
    );
  }

  getCompanyAdminByCompany(companyId): Observable<any> {
    return this.http.get(
      this.baseUrl +
      "/human-resources/company-admin/by-company?company_id=" +
      companyId
    );
  }

  getByCompany(company_id): Observable<HumanResource> {
    return this.http.get<HumanResource>(
      this.baseUrl + "/human-resources/by-company?company_id=" + company_id
    );
  }
  

  getByCareerId(career_id): Observable<any> {
    return this.http.get(
      this.baseUrl + "/human-resources/by-career?career_id=" + career_id
    );
  }

  getBySupervisorId(supervisor_id): Observable<any> {
    return this.http.get(
      this.baseUrl +
      "/human-resources/by-supervisor-id?supervisor_id=" +
      supervisor_id
    );
  }
  getByCompanyEntityId(company_entity_id): Observable<any> {
    return this.http.get(
      this.baseUrl +
      "/human-resources/company-admin?company_entity_id=" +
      company_entity_id
    );
  }

  getAllDataBySupervisorId(supervisor_id): Observable<any> {
    return this.http.get(
      this.baseUrl +
      "/human-resources/by-supervisor?supervisor_id=" +
      supervisor_id
    );
  }

  getAllDataByHumanResourceId(id): Observable<any> {
    return this.http.get(this.baseUrl + "/human-resources/" + id + "/details");
  }

  createConsultant(humanResource: HumanResource, language: string) {
    return this.http.post(
      this.baseUrl + "/human-resources/consultants?language=" + language,
      humanResource
    );
  }
  createManager(hr: HumanResource, language: string) {
    return this.http.post(
      this.baseUrl + "/human-resources/managers?language=" + language,
      hr
    );
  }
  createCompanyAdmin(hr: HumanResource, language: string):Observable<HumanResource> {
    return this.http.post<HumanResource>(
      this.baseUrl + "/human-resources/company-admin?language=" + language,
      hr
    );
  }
  setAlreadyLoggedInToTrue(id) {
    return this.http.put(
      this.baseUrl + "/human-resources/update-logged-in?id=" + id,
      {}
    );
  }

  updateHumanResource(id, hr: HumanResource) {
    return this.http.put(this.baseUrl + "/human-resources/" + id, hr);
  }

  deleteHumanResource(id: number) {
    return this.http.delete(this.baseUrl + "/human-resources/" + id);
  }


  confirmRegistration(mailToken: string, accessToken): Observable<any> {
    return this.http.get(
      this.baseUrl + "/confirm-registration?token=" + mailToken,
      {
        headers: {
          Authorization: `Bearer ${accessToken.access_token}`,
        },
      }
    );
  }

  getAllConultants(): Observable<any> {
    return this.http.get(this.baseUrl + "/human-resources/consultants");
  }

  getByRole(role: any): Observable<any> {
    return this.http.get(
      this.baseUrl + "/human-resources/by-role?role=" + role
    );
  }

  updateHumanResourceAvatar(id, avatarUrl) {
    return this.http.put(
      this.baseUrl + "/human-resources/" + id + "/avatar",
      avatarUrl
    );
  }

  getHumanResourcesByCompanyId(id): Observable<HumanResource[]> {
    return this.http.get<HumanResource[]>(
      this.baseUrl + "/human-resources/by-company?company_id=" + id
    );
  }

  getManagersByCompanyId(id): Observable<any> {
    return this.http.get(
      this.baseUrl + "/human-resources/managers/by-company?company_id=" + id
    );
  }

  getHrsByCompanyId(id): Observable<any> {
    return this.http.get(
      this.baseUrl + "/human-resources/hrs/by-company?company_id=" + id
    );
  }

  getConsultantsByCompanyId(id): Observable<any> {
    return this.http.get(
      this.baseUrl + "/human-resources/consultant/by-company?company_id=" + id
    );
  }

  getManagersByCompanyEntityId(id: bigint): Observable<HumanResource[]> {
    return this.http.get<HumanResource[]>(
      this.baseUrl + "/human-resources/managers/by-entity?entity_id=" + id);
  }

  getConsultants(): Observable<any> {
    return this.http.get(
      this.baseUrl + "/human-resources/consultants"
    );
  }

  getConsultantsByCompanyEntityId(id: bigint): Observable<HumanResource[]> {
    return this.http.get<HumanResource[]>(
      this.baseUrl + "/human-resources/consultants/by-company-entity-id/" + id);

  }

  getConsultantsByRole(id): Observable<any> {
    return this.http.get(
      this.baseUrl + `/human-resources-by-role-and-entity?role=Consultant&companyEntityId=${id}`
    );
  }

  generateToken(): Observable<any> {
    return this.http.get(this.baseUrl + "/human-resources/sas-token");
  }

  uploadAvatar(hrId, avatar): Observable<any> {
    return this.http.post(
      this.baseUrl + "/human-resources/avatar?hr_id=" + hrId,
      avatar
    );
  }

  getBestCooptors(companyId: bigint): Observable<any> {
    return this.http.get(this.baseUrl + "/human-resources/best-cooptors/"+BigInt(companyId));
  }

  createHr(formData, language): Observable<any>  {
    return this.http.post(
      this.baseUrl + `/human-resources/hr?language=${language}`,
      formData);
  }

  createVisitor(formData, language, accessToken: Token) {
    return this.http.post(
      this.baseUrl + `/human-resources/visitor?language=${language}`,
      formData,
      {
        headers: {
          Authorization: "Bearer" + accessToken.access_token
        },
      }

    );

  }

  updatePassword(id, object) {
    return this.http.put(this.baseUrl + "/users/" + id, object);
  }


  sendEmailToUser(email: String, language: String, accessToken: Token) {
    const requestParam = `?language=${language}&email=${email}`
    let headers = { Authorization: "Bearer " + accessToken.access_token };
    return this.http.post(`/hr-ms/api/v1/human-resources/mail${requestParam}`, {},
      {
        headers: {
          Authorization: "Bearer " + accessToken.access_token
        },
      }
    );
  }

  addMultiple(file, language: string, hr, type) {
    let form: FormData = new FormData()
    form.append("file", file, "file")

    return this.http.post(
      this.baseUrl + `/human-resources/multiple?type=${type}&hr=${hr}&language=${language}`, form, { responseType: 'blob' }
    );
  }

  getLogo(tenant): Observable<any> {
    return this.http.get(`https://captivaostalenteo.blob.core.windows.net/companies/${tenant}_logo.png`, { responseType: 'blob' })
  }

  generateTemplateFile(companyId: any): Observable<any> {
    return this.http.get(this.baseUrl + `/generate-template-file?companyId=${companyId}`, { responseType: 'blob' });
  }

  getHrMailsDto(companyId): Observable<any> {
    return this.http.get(this.baseUrl + `/human-resources-mails?companyId=${companyId}`);
  }

  sendNews(mails): Observable<any> {
    return this.http.post(this.baseUrl + "/human-resources-send-news", mails);
  }

  getMailsByCompany(company_id: bigint): Observable<String[]>{
    return this.http.get<String[]>(this.baseUrl + `human-resources/mails/by-company/${company_id}`)
  }

  uploadHrAvatar(id: bigint, frm: FormData){
    return this.http.post(`${this.baseUrl}/human-resources/${id}/blob`, frm);
  }

  getAllDataByCompanyId(company_id): Observable<any> {
    return this.http.get(
      this.baseUrl +
      "/human-resources/by-company_id?company_id=" +
      company_id
    );
  }

  getAllDataBySupervisorIdAndRole(supervisor_id,role): Observable<any> {
    return this.http.get(
      this.baseUrl +
      "/human-resources/role/by-supervisor_id?supervisor_id=" +
      supervisor_id+"&role="+role
    );
  }
}
