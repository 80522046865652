import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Consultant } from '../model/Consultant';
import { Status } from '../model/Status';

@Injectable({
  providedIn: 'root'
})
export class ConsultantService {
  baseUrl: string = "/mission-ms/api/v1/consultant";

  constructor(private http: HttpClient) { }


  getAll(): Observable<Consultant[]> {
    return this.http.get<Consultant[]>(this.baseUrl);
  }

  getConsultantById(id: bigint): Observable<Consultant> {
    return this.http.get<Consultant>(`${this.baseUrl}/${id}`);
  }
  addConsultant(consultant: Consultant): Observable<Consultant> {
    return this.http.post<Consultant>(this.baseUrl, consultant);
  }

  removeConsultant(id: bigint) {
    return this.http.delete(`${this.baseUrl}/${id}`);
  }

  updateConsultant(consultant: Consultant): Observable<Consultant> {
    return this.http.put<Consultant>(this.baseUrl, consultant);
  }

  getConsultantByHrId(id: bigint): Observable<Consultant> {
    return this.http.get<Consultant>(`${this.baseUrl}/hr/${id}`);
  }

  getListConsultantOfCurrentLoggedUser(): Observable<Consultant[]> {
    return this.http.get<Consultant[]>(`${this.baseUrl}/hr/currentUser`);
  }

  getStatusOfConsultant(id:bigint): Observable<Status> {
    return this.http.get<Status>(`${this.baseUrl}/${id}/status`);
  }

  updateConsultantStatus(consultant: Consultant, statusCode: string): Observable<Consultant>{
    return this.http.put<Consultant>(`${this.baseUrl}/${statusCode}`,consultant)
  }
  
}
