// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  azureContainers: {
    storageUri: "https://captivaostalenteo.blob.core.windows.net",
    storageAccessToken: "?sp=r&st=2024-01-08T10:12:14Z&se=2025-07-01T18:12:14Z&sv=2022-11-02&sr=c&sig=t7%2FpyK%2BOImoRugYSAZCQB%2BZq0VgTMovlFwcLRdJhqJM%3D",
    companiesContainer: "companies",
    usersContainer: "users-avatars",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

