import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Status } from '../model/Status';

@Injectable({
  providedIn: 'root'
})
export class StatusService {
  baseUrl: string = "/mission-ms/api/v1/status";

  constructor(private http: HttpClient) { }

  getAllMissionStatus(): Observable<Status[]> {
    return this.http.get<Status[]>(this.baseUrl + "/mission");
  }

  getAllConsultantStatus(): Observable<Status[]> {
    return this.http.get<Status[]>(this.baseUrl + "/consultant");
  }

  updateConsultantStatus(id:bigint): Observable<Status>{
    return this.http.put<Status>(`${this.baseUrl}/consultant/${id}`,status)
  }

  getStatusByCode(code:string): Observable<Status> {
    return this.http.get<Status>(`${this.baseUrl}/${code}`);
  }
}
