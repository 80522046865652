import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  NgbButtonsModule,
  NgbDropdownModule,
  NgbTabsetModule,
  NgbTooltipModule,
} from "@ng-bootstrap/ng-bootstrap";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { MDBBootstrapModule } from "angular-bootstrap-md";
import { Ng5SliderModule } from "ng5-slider";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import {
  BLOB_STORAGE_TOKEN,
  IBlobStorage,
} from "./ihm/shared/azure-storage/azureStorage";
import { TenantModule } from "./ihm/shared/tenant/tenant.module";
import { AuthInterceptorProvider } from "./interceptor/auth.interceptor";
import { AdminComponent } from "./theme/layout/admin/admin.component";
import { ConfigurationComponent } from "./theme/layout/admin/configuration/configuration.component";
import { NavBarComponent } from "./theme/layout/admin/nav-bar/nav-bar.component";
import { NavLeftComponent } from "./theme/layout/admin/nav-bar/nav-left/nav-left.component";
import { NavSearchComponent } from "./theme/layout/admin/nav-bar/nav-left/nav-search/nav-search.component";
import { NavRightComponent } from "./theme/layout/admin/nav-bar/nav-right/nav-right.component";
import { NavCollapseComponent } from "./theme/layout/admin/navigation/nav-content/nav-collapse/nav-collapse.component";
import { NavContentComponent } from "./theme/layout/admin/navigation/nav-content/nav-content.component";
import { NavGroupComponent } from "./theme/layout/admin/navigation/nav-content/nav-group/nav-group.component";
import { NavItemComponent } from "./theme/layout/admin/navigation/nav-content/nav-item/nav-item.component";
import { NavigationItem } from "./theme/layout/admin/navigation/navigation";
import { NavigationComponent } from "./theme/layout/admin/navigation/navigation.component";
import { AuthComponent } from "./theme/layout/auth/auth.component";
import { ToggleFullScreenDirective } from "./theme/shared/full-screen/toggle-full-screen";
import { SharedModule } from "./theme/shared/shared.module";
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { CommonModule } from "@angular/common";
import { BigIntInterceptor } from "./utils/big-int.interceptor";
import { NgSelectModule } from "@ng-select/ng-select";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SpinnerInterceptor } from "./interceptor/spinner.interceptor";
import { Spinner2Component } from "./ihm/shared/spinner/spinner.component";

 

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

export function azureBlobStorageFactory(): IBlobStorage {
  return window["AzureStorage"].Blob;
}

/* Menu Items */
@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent,
    NavigationComponent,
    NavContentComponent,
    NavGroupComponent,
    NavCollapseComponent,
    NavItemComponent,
    NavBarComponent,
    NavLeftComponent,
    NavSearchComponent,
    NavRightComponent,
    ConfigurationComponent,
    ToggleFullScreenDirective,
    Spinner2Component

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    TenantModule,
    HttpClientModule,
    Ng5SliderModule,
    BrowserAnimationsModule,
    MDBBootstrapModule.forRoot(),
    SharedModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbButtonsModule,
    HttpClientModule,
    FormsModule,
    Ng2SearchPipeModule,
    CommonModule,
    NgSelectModule,
    MatProgressSpinnerModule,
    NgbTabsetModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    })
  ],
  //providers: [NavigationItem, jwtInterceptorProvider, errorInterceptorProvider],
  providers: [
    NavigationItem,
    AuthInterceptorProvider,
    { provide: BLOB_STORAGE_TOKEN, useFactory: azureBlobStorageFactory },
    { provide: HTTP_INTERCEPTORS, useClass: BigIntInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true }


  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
