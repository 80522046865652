import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { CompanyEntity } from 'src/app/ms/hr-ms/model/CompanyEntity';
import { HumanResource } from 'src/app/ms/hr-ms/model/HumanResource';
import { CompanyEntityService } from 'src/app/ms/hr-ms/service/company-entity.service';
import { Consultant } from 'src/app/ms/mission-ms/model/Consultant';
import { Mission } from 'src/app/ms/mission-ms/model/Mission';
import { MissionService } from 'src/app/ms/mission-ms/service/mission.service';

@Component({
  selector: 'app-consultants',
  templateUrl: './consultants.component.html',
  styleUrls: ['./consultants.component.scss']
})
export class ConsultantsComponent implements OnInit {
  @ViewChild('dt') table: Table;
  user:HumanResource;
  showSecondCard = false;
  missionsList:Mission[];
  consultantsList:Consultant[]=[];
  totalMissions:number;
  totalConsultants:number;
  totalEntities:number;
  pageSize = 4;
  currentPage = 1;
  companiesCache: Map<bigint, CompanyEntity> = new Map<bigint, CompanyEntity>();
  companiesArray;
  consultant: any;
  mission:any;
  selectedName;
  selectedEntityId;
  selectedMissionId;
  searchedConsultants = [... this.consultantsList];
  isSelected;
  public timeCAC = {};
  selectedConsultant:any;
  constructor(private missionService:MissionService,
              private translate:TranslateService,
              private companyEntityService:CompanyEntityService,
              private modalService: NgbModal) { }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem("user-data"));
     this.getData();
     this.getAllCompaniesEntities();
  }

  getData() {
    this.missionService.getMissionsByClientContactEmail(this.user.email).subscribe((data: Mission[]) => {
      this.missionsList = data;
      this.totalMissions = this.missionsList.length;
      this.totalConsultants = this.calculateTotalConsultants();
      this.totalEntities = this.calculateTotalEntities();
      let allConsultants: any[] = [];
      this.missionsList.forEach((mission: any) => {
      allConsultants = allConsultants.concat(mission.consultants);
      });
        this.consultantsList = allConsultants;
        this.consultantsList.forEach(c=>{
          c.fullName=c.firstName+" "+c.lastName
        })
        this.searchedConsultants = [... this.consultantsList];

    });
  }

  calculateTotalConsultants(): number {
    return this.missionsList.reduce((total, mission) => total + mission.consultants.length, 0);
  }

  calculateTotalEntities(): number {
    const uniqueCompanyIds = new Set<bigint>();
      this.missionsList.forEach((mission) => {
      uniqueCompanyIds.add(mission.companyId);
    });
      return uniqueCompanyIds.size;
  }

  getStatusText(statusCode: string): string {
    let statusText: string;

    switch (statusCode) {
      case '1':
        statusText = this.translate.instant("TEXT_CONSULTANT_STATUS_EN_MISSION");
        break;
      case '2':
        statusText = this.translate.instant("TEXT_CONSULTANT_STATUS_EN_INTERCONTRAT");
        break;
      case '3':
        statusText = this.translate.instant("TEXT_CONSULTANT_STATUS_EN_INTEGRATION");
        break;
      default:
        statusText = '';
        break;
    }

    return statusText;
  }

  getAllCompaniesEntities(){
    this.companyEntityService.getAllCompaniesEntity().subscribe((data)=>{
      this.companiesArray=data;
      this.companiesArray.forEach(elt => {
        this.companiesCache.set(elt.id,elt.label)
      });
    })
    }

    compare(a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    }

    searchByConsultantName():void{
      if (this.selectedName) {
        this.searchedConsultants = this.consultantsList.filter(c =>
          c.fullName.toLowerCase().includes(this.selectedName)
        );
      } else {
        this.searchedConsultants = [...this.consultantsList];
      }
    }

    searchByEntityLabel():void{
      if (this.selectedEntityId) {
        this.searchedConsultants = this.consultantsList.filter(c =>
          c.companyEntityId==this.selectedEntityId
        );
      } else {
        this.searchedConsultants = [...this.consultantsList];
      }
    }

    searchByMission(): void {
      if (this.selectedMissionId) {
        const selectedMission = this.missionsList.find(m => m.id === this.selectedMissionId);
        if (selectedMission) {
          this.searchedConsultants = selectedMission.consultants;
        } else {
          this.searchedConsultants = [...this.consultantsList];
        }
      } else {
        this.searchedConsultants = [...this.consultantsList];
      }
    }
    
    
}
