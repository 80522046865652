<app-card hidHeader="true">
    <div class="alert alert-danger text-center" role="alert" *ngIf="totalConsultants==undefined || totalConsultants==0">
        {{"TEXT_NO_EVALUATIONS_FOUND" | translate}}
    </div>
    <app-card [cardTitle]="'Consultants'" [options]="false" [titleColor]="'#008b8b'">
        <div class="row">
            <div class="col-md-2 col-xs-6 border-right">
                <h3>{{totalConsultants}}</h3>
                <span>
                    {{ "TEXT_TOTAL_CONSULTANTS" | translate }}
                </span>
            </div>
        </div>
    </app-card>
    <div *ngIf="totalConsultants!=undefined || totalConsultants!=0">
        <app-card [hidHeader]="true" cardClass="user-profile-list">
            <div class="dt-responsive table-responsive">
        <!-- <table [dtOptions]="dtOptions" datatable class="table table-striped row-border table-hover" *ngIf="consultantsList?.length">
            <thead>
                <tr>
                    <th>{{ "TABLE_HEADER_NAME" | translate }}</th>
                    <th>{{ "TABLE_HEADER_ENTITY" | translate }}</th>
                    <th>{{ "TABLE_HEADER_STATUS" | translate }}</th>
                    <th>{{ "TABLE_HEADER_OPTIONS" | translate }}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let consultant of searchedConsultants">
                    <td>{{ consultant.firstName }} {{ consultant.lastName }}</td>
                    <td>{{ companiesCache.get(consultant.companyEntityId) }}</td>
                    <td>
                        <span class="badge" [ngClass]="{
                                'badge-success': consultant.statusCode == 1,
                                'badge-warning': consultant.statusCode == 2,
                                'badge-danger': consultant.statusCode == 3,
                                'badge-secondary': consultant.statusCode != 1 && consultant.statusCode != 2 && consultant.statusCode != 3
                            }">
                            {{ getStatusText(consultant.statusCode) }}
                        </span>
                    </td>
                    <td>
                        <app-valuation class="evaluation" [consultant]="consultant"></app-valuation>
                    </td>
                </tr>
            </tbody>
        </table> -->
    </div>
</app-card>
    </div>
</app-card>
