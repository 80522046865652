<ul class="navbar-nav mr-auto">
  <li class="nav-item">
    <!-- <app-nav-search></app-nav-search> -->
  </li>
  <li class="pl-4">
    <div *ngIf="langue === 'en'" class="dropdown" ngbDropdown placement="auto">
      <a href="javascript:" ngbDropdownToggle data-toggle="dropdown">
        <span class="famfamfam-flags gb"></span>
      </a>
      <div class="dropdown-menu" ngbDropdownMenu>
        <div class="pro-body">
          <a class="dropdown-item" (click)="useLanguage('en')">
            <span class="famfamfam-flags gb"></span>

            English</a
          >

          <a class="dropdown-item" (click)="useLanguage('fr')">
            <span class="famfamfam-flags fr"></span>

            French</a
          >
        </div>
      </div>
    </div>

    <div *ngIf="langue === 'fr'" class="dropdown" ngbDropdown placement="auto">
      <a href="javascript:" ngbDropdownToggle data-toggle="dropdown">
        <span class="famfamfam-flags fr" style="font-size: larger;"></span>
      </a>
      <div class="dropdown-menu" ngbDropdownMenu>
        <div class="pro-body">
          <a class="dropdown-item" (click)="useLanguage('en')">
            <span class="famfamfam-flags gb"></span>

            Anglais</a
          >

          <a class="dropdown-item" (click)="useLanguage('fr')">
            <span class="famfamfam-flags fr"></span>

            Français</a
          >
        </div>
      </div>
    </div>
  </li>
</ul>
