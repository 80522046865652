import { Component, OnInit } from '@angular/core';
import { SpinnerService } from 'src/app/ms/hr-ms/service/spinner.service';

@Component({
  selector: 'app-spinner2',
  template: `
    <div *ngIf="isLoading" class="spinner-overlay">
        <div class="spinner"></div>
    </div>`,
  styleUrls: ["./spinner.component.scss"],

})
export class Spinner2Component implements OnInit {
  isLoading: boolean = false;

  constructor(private spinnerService: SpinnerService) { }

  ngOnInit() {
    this.spinnerService.isLoading.subscribe((loading: boolean) => {
      this.isLoading = loading;
    });
  }
}