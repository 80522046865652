import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";

import {AuthorizationGuard} from "./security/authorization.guard";
import {AdminComponent} from "./theme/layout/admin/admin.component";
import {AuthComponent} from "./theme/layout/auth/auth.component";
import {HUMAN_RESOURCE_ROLES} from "./utils/constants";
import { AssessmentFormComponent } from "./ihm/pages/missions/assessment-form/assessment-form.component";
import { MissionClientContactComponent } from "./ihm/pages/missions/mission-client-contact/missions/mission-client-contact.component";
import { ConsultantsComponent } from "./ihm/pages/missions/mission-client-contact/consultants/consultants.component";

const routes: Routes = [
  {
    path: "",
    component: AdminComponent,
    children: [

      {
        path: "dashboard",
        canActivate: [AuthorizationGuard],
        loadChildren: () =>
          import("./ihm/pages/dashboard/dashboard.module").then(
            (module) => module.DashboardModule
          ),
      },
      /* {
        path: "search",
        canActivate: [AuthorizationGuard],
        loadChildren: () =>
          import("./ihm/pages/search/search.module").then(
            (module) => module.SearchModule
          ),
        data: {
          roles: [HUMAN_RESOURCE_ROLES.BUSINESS_MANAGER, HUMAN_RESOURCE_ROLES.COMPANY_ADMIN],
        },
      }, */
      {
        path: "human-resources",
        canActivate: [AuthorizationGuard],
        loadChildren: () =>
          import("./ihm/pages/human-resources/human-resources.module").then(
            (module) => module.HumanResourcesModule
          ),
        data: {
          roles: [HUMAN_RESOURCE_ROLES.BUSINESS_MANAGER, HUMAN_RESOURCE_ROLES.COMPANY_ADMIN, HUMAN_RESOURCE_ROLES.HUMAN_RESOURCE],
        },
      },
      {
        path: "send-news",
        canActivate: [AuthorizationGuard],
        loadChildren: () =>
          import("./ihm/pages/send-news/send-news.module").then(
            (module) => module.SendNewsModule
          ),
        data: {
          roles: [HUMAN_RESOURCE_ROLES.BUSINESS_MANAGER, HUMAN_RESOURCE_ROLES.COMPANY_ADMIN, HUMAN_RESOURCE_ROLES.HUMAN_RESOURCE],
        },
      },
      {
        path: "skills",
        canActivate: [AuthorizationGuard],
        loadChildren: () =>
          import("./ihm/pages/skills/skills.module").then(
            (module) => module.SkillsModule
          ),
        // data: {
        //   roles: [HUMAN_RESOURCE_ROLES.COMPANY_ADMIN],
        // },
      },
      {
        path: "cooptations",
        canActivate: [AuthorizationGuard],
        loadChildren: () =>
          import("./ihm/pages/cooptation/cooptation.module").then(
            (module) => module.CooptationModule
          ),
        data: {
          roles: ["CONSULTANT", "BUSINESS_MANAGER", "COMPANY_ADMIN", "HUMAN_RESOURCE", "VISITOR"],
        },
      },
      {
        path: "requirements",
        canActivate: [AuthorizationGuard],
        loadChildren: () =>
          import("./ihm/pages/requirements/requirements.module").then(
            (module) => module.RequirementsModule
          ),
        data: {
          roles: ["CONSULTANT", "BUSINESS_MANAGER", "COMPANY_ADMIN", "HUMAN_RESOURCE", "VISITOR"],
        },
      },
      {
        path: "skills",
        canActivate: [AuthorizationGuard],
        loadChildren: () =>
          import("./ihm/pages/skills/skills.module").then(
            (module) => module.SkillsModule
          ),
        // data: {
        //   roles: [HUMAN_RESOURCE_ROLES.COMPANY_ADMIN],
        // },
      },

      {
        path: "technical-document",
        canActivate: [AuthorizationGuard],
        loadChildren: () =>
          import(
            "./ihm/pages/technical-document/technical-document.module"
            ).then((module) => module.TechnicalDocumentModule),
        data: {
          roles: [HUMAN_RESOURCE_ROLES.CONSULTANT, HUMAN_RESOURCE_ROLES.BUSINESS_MANAGER, HUMAN_RESOURCE_ROLES.COMPANY_ADMIN],
        },
      },

     {
         path: "assessments",
         canActivate: [AuthorizationGuard],
         loadChildren: () =>
           import("./ihm/pages/assessments/assessments.module").then(
             (module) => module.AssessmentsModule
           ),
         data: {
           roles: [HUMAN_RESOURCE_ROLES.CONSULTANT, HUMAN_RESOURCE_ROLES.BUSINESS_MANAGER, HUMAN_RESOURCE_ROLES.COMPANY_ADMIN],
         },
       },
       {
         path: "missions",
         canActivate: [AuthorizationGuard],
         loadChildren: () =>
           import("./ihm/pages/missions/missions.module").then(
             (module) => module.MissionsModule
           ),
         data: {
           roles: [HUMAN_RESOURCE_ROLES.CONSULTANT, HUMAN_RESOURCE_ROLES.BUSINESS_MANAGER, HUMAN_RESOURCE_ROLES.COMPANY_ADMIN],
         },
       },
       {
        path: "mission",
        canActivate: [AuthorizationGuard],
        loadChildren: () =>
          import("./ihm/pages/mission-consultant/mission-consultant.module").then(
            (module) => module.MissionConsultantModule
          ),
        data: {
          roles: [HUMAN_RESOURCE_ROLES.CONSULTANT],
        },
      },
      {
        path: "client_contact/missions",
        canActivate: [AuthorizationGuard],
        component: MissionClientContactComponent,
        loadChildren: () =>
          import("./ihm/pages/missions/mission-client-contact/mission-client-contact.module").then(
            (module) => module.MissionClientContactModule
          ),
        data: {
          roles: [HUMAN_RESOURCE_ROLES.CLIENT_CONTACT],
        },
      }, 
      {
        path: "client_contact/consultants",
        canActivate: [AuthorizationGuard],
        component: ConsultantsComponent,
        loadChildren: () =>
          import("./ihm/pages/missions/mission-client-contact/mission-client-contact.module").then(
            (module) => module.MissionClientContactModule
          ),
        data: {
          roles: [HUMAN_RESOURCE_ROLES.CLIENT_CONTACT],
        },
      }, 

      {
        path: "projects",
        canActivate: [AuthorizationGuard],
        loadChildren: () =>
          import("./ihm/pages/projects/projects.module").then(
            (module) => module.ProjectsModule
          ),
        data: {
          roles: [HUMAN_RESOURCE_ROLES.CONSULTANT, HUMAN_RESOURCE_ROLES.BUSINESS_MANAGER, HUMAN_RESOURCE_ROLES.COMPANY_ADMIN],
        },
      },
      {
        path: "companies",
        canActivate: [AuthorizationGuard],
        loadChildren: () =>
          import("./ihm/pages/companies/companies.module").then(
            (module) => module.CompaniesModule
          ),
        data: {
          roles: [HUMAN_RESOURCE_ROLES.SUPER_ADMIN],
        },
      },
      {
        path: "entities",
        canActivate: [AuthorizationGuard],
        loadChildren: () =>
          import("./ihm/pages/entities/entities.module").then(
            (m) => m.EntityModule
          ),
        data: {
          roles: [HUMAN_RESOURCE_ROLES.COMPANY_ADMIN, HUMAN_RESOURCE_ROLES.BUSINESS_MANAGER, HUMAN_RESOURCE_ROLES.HUMAN_RESOURCE],
        },
      },
      {
        path: "settings",
        canActivate: [AuthorizationGuard],
        loadChildren: () =>
          import("./ihm/pages/settings/settings.module").then(
            (module) => module.SettingsModule
          ),
      },
      {
        path: "error",
        canActivate: [AuthorizationGuard],
        loadChildren: () =>
          import("./ihm/pages/error/error.module").then(
            (module) => module.ErrorModule
          ),
      },
      {
        path: "",
        redirectTo: "dashboard",
        pathMatch: "full",
      },
    ],
  },

  {
    path: "",
    component: AuthComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./ihm/pages/authentication/authentication.module").then(
            (module) => module.AuthenticationModule
          ),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
