import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { shareReplay, switchMap, take } from 'rxjs/operators';
import { HumanResource } from 'src/app/ms/hr-ms/model/HumanResource';
import { CompanyEntityService } from 'src/app/ms/hr-ms/service/company-entity.service';
import { HumanResourceService } from 'src/app/ms/hr-ms/service/human-resource.service';
import { Consultant } from 'src/app/ms/mission-ms/model/Consultant';
import { Mission } from 'src/app/ms/mission-ms/model/Mission';
import { Status } from 'src/app/ms/mission-ms/model/Status';
import { ConsultantService } from 'src/app/ms/mission-ms/service/consultant.service';
import { StatusService } from 'src/app/ms/mission-ms/service/status.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-consultant-detail',
  templateUrl: './consultant-detail.component.html',
  styleUrls: ['./consultant-detail.component.scss']
})
export class ConsultantDetailComponent implements OnInit {
  humanResource: HumanResource;
  user: HumanResource;
  public frm: FormGroup;
  company_id: any;
  error: any = { isError: false, errorMessage: "" };
  error_client: any = { isError: false, errorMessage: "" };
  public editConsultant: boolean;
  public editConsultantIcon: string;
  fullname: String;
  email: string;
  avatar: string;
  phoneNumber: string;
  birthDate: Date;
  lastname: string;
  firstname: string;
  managerId:any;
  editStatus: boolean;
  editStatusIcon: string;
 
  statusList: Status[] = [];
  selectedStatus: any;
  missions: Mission[];
  managerName:String;
  @Input() consultant: any;
  @Input() companiesCache:any;
  @Input() mission:any
  @Input() showViewConsultant;
  @Output() event = new EventEmitter();
  constructor(public consultantService: ConsultantService,
    private translate: TranslateService,
    private statusService: StatusService,
    private companyEntityService:CompanyEntityService,
    private hrService:HumanResourceService

  ) {
    translate.setDefaultLang(localStorage.getItem("language"));
    this.editConsultant = false;
    this.editConsultantIcon = "icon-edit";
    this.editStatus = false;
    this.editStatusIcon = "icon-edit";

  }


  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem("user-data"));
    this.company_id = this.user
      ? Number(this.user.companyEntity?.company.id)
      : null;
      this.getConsultantStatus();
      this.fullname = this.consultant.firstName+ " " + this.consultant.lastName;
    this.email = this.consultant.email;
    this.avatar = this.consultant.avatar;
    this.phoneNumber = this.consultant.phoneNumber;
    this.birthDate = this.consultant.birthDate;
    this.lastname = this.consultant.lastname;
    this.firstname = this.consultant.firstname;
    this.managerId-this.consultant.managerId;

    this.getManagerName();
    
   
  }

  getStatusText(statusCode: string): string {
    let statusText: string;

    switch (statusCode) {
      case '1':
        statusText = this.translate.instant("TEXT_CONSULTANT_STATUS_EN_MISSION");
        break;
      case '2':
        statusText = this.translate.instant("TEXT_CONSULTANT_STATUS_EN_INTERCONTRAT");
        break;
      case '3':
        statusText = this.translate.instant("TEXT_CONSULTANT_STATUS_EN_INTEGRATION");
        break;
      default:
        statusText = '';
        break;
    }

    return statusText;
  }

  getConsultantStatus() {
    this.statusService.getAllConsultantStatus().subscribe((resp) => {
      this.statusList = resp;
    },
      (err) => {
        
      }
    )
  }

  onStatusSelected(event: any) {
    this.selectedStatus = event;
    this.consultantService.updateConsultantStatus(this.consultant, this.selectedStatus.code).subscribe((resp) => {
      Swal.fire(
        "",
        this.translate.instant("TEXT_CONSULTANT_STATUS_UPDATED"),
        "success"
      );
         this.consultant.statusCode=this.selectedStatus.code;
         this.editStatus=!this.editStatus;
},
      (error) => {
        Swal.fire(
          "",
          this.translate.instant("TEXT_ERROR"),
          "error"
        );
      }
    )
  }

  // getCompanyEntityLabel(companyEntityId: bigint): Observable<string> {
    
  //   const cachedCompany$ = this.companiesCache.get(companyEntityId);
  //   if (cachedCompany$) {
  //     return of(cachedCompany$);
  //   }
  //     const type$ = this.companyEntityService.getCompanyById(companyEntityId).pipe(
  //     switchMap((companyEntity) => {
  //       return of(companyEntity.label as string);
  //     }),
  //     take(1),
  //     shareReplay(1)
  //   );
  //     type$.subscribe((label) => {
  //     this.companiesCache.set(companyEntityId, label);
  //   }); 
  
  //   return type$;
  // }


  getManagerName(){
    this.hrService.getById(this.consultant.managerId).subscribe((data)=>{
      this.managerName=data.firstname +" "+ data.lastname;
    })
  }


}

