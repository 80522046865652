<meta name="viewport" content="width=device-width, initial-scale=1.0" />
<div class="alert alert-danger text-center" role="alert" *ngIf="totalMissions == undefined || totalMissions == 0">
    {{"TEXT_NO_EVALUATIONS_FOUND" | translate}}
</div>
<div *ngIf="totalMissions != undefined || totalMissions != 0">
    <app-card [cardTitle]="'Missions'" [options]="false" [titleColor]="'#008b8b'">
        <div class="row">
            <div class="col-md-2 col-xs-6 border-right">
                <h3>{{ totalMissions }}</h3>
                <span>{{ "TEXT_TOTAL_MISSIONS" | translate }}</span>
            </div>
        </div>
    </app-card>
    <div class="container-fluid ">
        <div class="row btn-page ">
            <div class="col-12">
                <div class="row align-items-center m-l-0">
                    <div class="col-sm-6"></div>
                </div>
                <app-card [hidHeader]="true" cardClass="user-profile-list">
                    <div class="dt-responsive table-responsive">
                        <p-table 
                        #dt
                            [value]="missionsList"
                            [paginator]="true" 
                            [rows]="10" 
                            [rowsPerPageOptions]="[10, 25, 50]" 
                            [loading]="loading"
                            [globalFilterFields]="['name', 'startDate', 'endDate', 'status.label']"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            responsiveLayout="scroll"
                            dataKey="id"
                            styleClass="p-datatable-missions"
                            [rowHover]="true"
                            [showCurrentPageReport]="true">
                            
                            <ng-template pTemplate="header">
                                <tr>
                                    <th colspan="7">
                                        <div class="table-header">
                                            <h1></h1>
                                            <span class="p-input-icon-left">
                                                <i class="pi pi-search"></i>
                                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Global Search" />
                                            </span>
                                        </div>
                                    </th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th pSortableColumn="name">{{ "TABLE_HEADER_MISSION" | translate }}<p-sortIcon field="name"></p-sortIcon></th>
                                    <th pSortableColumn="startDate">{{ "TABLE_HEADER_START_DATE" | translate }}<p-sortIcon field="startDate"></p-sortIcon></th>
                                    <th pSortableColumn="endDate">{{ "TABLE_HEADER_END_DATE" | translate }}<p-sortIcon field="endDate"></p-sortIcon></th>
                                    <th>{{ "TABLE_HEADER_STATUT" | translate }}</th>
                                    <th pSortableColumn="consultants.length">{{ "TABLE_HEADER_TOTAL_CONSULTANTS" | translate }}<p-sortIcon field="consultants.length"></p-sortIcon></th>
                                    <th>{{ "TABLE_HEADER_OPTIONS" | translate }}</th>
                                    
                                </tr>
                                <tr>
                                    <th>
                                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                    </th>
                                    <th><input pInputText type="text" (input)="dt.filter($event.target.value, 'name', 'contains')" placeholder="Search by Name" class="p-column-filter" /></th>
                                    <th>
                                        <p-calendar (onSelect)="onStartDateSelect($event)" appendTo="body" (onClearClick)="dt.filter('', 'startDate', 'equals')"
                                            [showButtonBar]="true" styleClass="p-column-filter" placeholder="Search by Start Date" [readonlyInput]="true"
                                            dateFormat="yy-mm-dd"></p-calendar>
                                    </th>
                                    <th>
                                        <p-calendar (onSelect)="onEndDateSelect($event)" [showButtonBar]="true" appendTo="body" placeholder="Search by End Date"  dateFormat="yy-mm-dd"></p-calendar>
                                    </th>
                                    <th><p-dropdown [options]="statuses" (onChange)="dt.filter($event.value, 'status.label', 'equals')" placeholder="Select a Status"></p-dropdown></th>
                                    <th>
                                        <input pInputText type="text" (input)="dt.filter($event.target.value, 'consultants.length', 'startsWith')" placeholder="Search by Total Consultants" class="p-column-filter" />
                                    </th>
                                    <th></th>
                                    
                                </tr>
                            </ng-template>

                            <ng-template pTemplate="body" let-mission let-expanded="expanded">
                                <tr>
                                    <td>
                                        <div class="button-checkbox-container">
                                            <p-tableCheckbox [value]="mission"></p-tableCheckbox>
                                            <button type="button" pButton pRipple [pRowToggler]="mission" class="round-button" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                                        </div>
                                    </td>
                                    <td>{{ mission.name }}</td>
                                    <td>{{ mission.startDate | date: "longDate" : "+0200" : language }}</td>
                                    <td>{{ mission.endDate | date: "longDate" : "+0200" : language }}</td>
                                    <td>
                                        <span class="badge" [ngClass]="{
                                            'badge-success': mission.status.label == 'EN_COURS',
                                            'badge-warning': mission.status.label == 'SUSPENDUE',
                                            'badge-danger': mission.status.label == 'ANNULEE',
                                            'badge-secondary': mission.status.label != 'EN_COURS' && mission.status.label != 'SUSPENDUE' && mission.status.label != 'ANNULEE'
                                        }">
                                            {{ getStatusText(mission.status.label) }}
                                        </span>
                                    </td>
                                    <td>
                                        <span class="consultant-length">{{mission.consultants.length}}</span>
                                    </td>
                                    <td>
                                        <a (click)="getDetail(mission)"><i class="icon feather icon-eye f-w-600 f-16 m-r-15"></i></a>
                                        <a (click)="RemoveMission(mission)"><i class="feather icon-trash-2 f-w-600 f-16 text-c-red m-r-15"></i></a>
                                        <a *ngIf="mission.status.label=='EN_COURS'" title="Suspend" (click)="suspendMission(mission)">
                                            <i class="feather icon-x-circle f-w-600 f-16 text-c-orange"></i>
                                        </a>
                                        <a *ngIf="mission.status.label!='EN_COURS'" title="Continue" [ngClass]="{'disabled-link': true}">
                                            <i class="feather icon-x-circle f-w-600 f-16 text-c-grey"></i>
                                        </a>
                                    </td>
                                   
                                    
                                </tr>
                            </ng-template>

                            
                            <ng-template pTemplate="rowexpansion" let-mission>
                                <td colspan="7" class="nohoverr">
                                    <div class="p-3">
                                        <p-table [tableStyle]="{'min-width': '50rem'}" [value]="mission.consultants" responsiveLayout="scroll | stack" dataKey="id">
                                            <ng-template pTemplate="header">
                                                <tr>
                                                    <th><strong>{{ "fullName" | translate }}</strong></th>
                                                    <th class="client-contact-email">{{ "TABLE_HEADER_EMAIL_ADDRESS" | translate }}</th>
                                                    <th>{{ "TABLE_HEADER_ENTITY" | translate }}</th>
                                                    <th>{{ "TABLE_HEADER_DETAILS" | translate }}</th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-consultant>
                                                <tr>
                                                    <td class="client-contact-firstname expandedBackground">{{ consultant.firstName }} {{ consultant.lastName }}</td>
                                                    <td class="client-contact-email expandedBackground">
                                                        <a [href]="'mailto:' + consultant.email"> <strong class="badge badge-light">{{ consultant.email }}</strong></a>
                                                    </td>
                                                    <td class="expandedBackground">{{ companiesCache.get(consultant.companyEntityId) }}</td>
                                                    <td class="expandedBackground">
                                                        <a><i (click)="getDetailConsultant(consultant, mission)" class="icon feather icon-eye f-w-600 f-16 m-r-15"></i></a>
                                                        <a><i (click)="RemoveConsultant(consultant)" class="feather icon-trash-2 f-w-600 f-16 text-c-red"></i></a>
                                                    </td>
                                                    <app-consultant-detail
                                                        *ngIf=" showViewConsultant"
                                                        [mission]="mission"
                                                        [consultant]="consultant"
                                                        [showViewConsultant]="showViewConsultant"
                                                        (event)="this.showViewConsultant = $event"
                                                    ></app-consultant-detail>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="emptymessage">
                                                <tr>
                                                    <td colspan="6">{{ "ERROR_MISSION_WITH_NO_CONSULTANTS" | translate }}</td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                    </div>
                                </td>
                            </ng-template>

                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="7">{{ "ERROR_MISSION_WITH_NO_CONSULTANTS" | translate }}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </app-card>
            </div>
        </div>
    </div>
    <app-mission-detail *ngIf="showView" [mission]="mission" [showView]="showView" (event)="this.showView = $event"></app-mission-detail>
</div>
<ng-http-loader [backgroundColor]="'darkcyan'" [opacity]="0.6" [backdrop]="false"></ng-http-loader>
