import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-nav-left",
  templateUrl: "./nav-left.component.html",
  styleUrls: ["./nav-left.component.scss"],
})
export class NavLeftComponent implements OnInit {
  langue: string;

  constructor() {}

  ngOnInit() {
    this.langue = localStorage.getItem("language");
  }

  useLanguage(language: string) {
    localStorage.setItem("language", language);
    window.location.reload();
  }
}
