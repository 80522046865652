import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-line-chart",
  templateUrl: "./line-chart.component.html",
  styleUrls: ["./line-chart.component.scss"],
})
export class LineChartComponent implements OnInit {
  @Input() page: string;
  @Input() labels: string[];
  @Input() data: any[];
  @Input() translable: boolean;
  @Input() clickable: boolean;

  chartReady: boolean = false;

  public chartLabels: Array<any>;

  public chartDatasets: Array<any>;

  constructor(private router: Router, private translate: TranslateService) {}

  ngOnInit(): void {
    /* this.chartDatasets = [
      {
        label: "Iheb Sahloul",
        data: [9, 15, 23, 20, 25, 28, 34],
        borderColor: ["#AD35BA"],
        borderWidth: 2,
        pointBorderColor: "#fff",
        fill: false,
        //pointBackgroundColor: "rgba(173, 53, 186, 0.1)",
      },
      {
        label: "Walid Ben Smida",
        data: [6, 17, 21, 19, 20, 26, 29],
        borderColor: ["#AD35BA"],
        borderWidth: 2,
        pointBorderColor: "#fff",
        fill: false,

        //pointBackgroundColor: "rgba(173, 53, 186, 0.1)",
      },
    ]; */
    this.chartDatasets = this.data
      ? [
          ...this.data.map((elem) => {
            return {
              label: elem?.hrData?.firstname + " " + elem?.hrData?.lastname,
              data: [
                ...elem?.careerData?.assessments.map(
                  (assessment) => assessment.globalPerformanceRating
                ),
              ],
              borderColor: ["#AD35BA"],
              borderWidth: 2,
              pointBorderColor: "#fff",
              fill: false,
              //pointBackgroundColor: "rgba(173, 53, 186, 0.1)",
            };
          }),
        ]
      : [];
    this.chartReady = true;

    /* this.chartLabels = [
      "S1.2018",
      "S2.2018",
      "S1.2019",
      "S2.2019",
      "S1.2020",
      "S2.2020",
    ] */
    this.chartLabels = this.labels ? this.labels : [];
  }

  public chartType: string = "line";

  public chartOptions: any = {
    responsive: true,

    scales: {
      xAxes: [
        {
          scaleLabel: {
            labelString: "Semestre",
            display: true,
            fontColor: "darkcyan",
            fontSize: 20,
          },
        },
      ],
      yAxes: [
        {
          scaleLabel: {
            labelString: "Score",
            display: true,
            fontColor: "darkcyan",
            fontSize: 20,
          },
        },
      ],
    },
  };
  public chartClicked(e: any): void {}
  public chartHovered(e: any): void {}
}
