import { AfterViewInit, Injectable } from '@angular/core';
import { HttpHeaders } from "@angular/common/http";


@Injectable({
  providedIn: 'root'
})
export class TenantService {
  constructor() { }

  test: String[] = [];

  getTenantForHostname(hostname: string, companies?) {
    hostname = hostname.substr(4, hostname.length)
    return this.getTenantForHost(hostname.split(".")[0], companies);
  }

  getTenantForString(s: string, companies?) {
    let exist = null;
    if (companies) {
      companies.forEach(element => {
        if (element.label.toLowerCase() === s.toLowerCase()) {
          exist = element.label.toLowerCase();
        }
      })
    }
    return exist;
  }

  getTenantForHost(host: string, companies?) {
    return this.getTenantForString(host, companies);
  }

  getTenant(companies?) {
    return this.getTenantForHostname(location.hostname, companies);
  }

  addTenantToHeaders(headers: HttpHeaders): HttpHeaders {
    let tenant = this.getTenant();
    if (tenant) {
      return headers.append("X-Tenant-ID", this.getTenant());
    }
    return headers;
  }

}



export enum Tenant {
  enum = "enum"
}
