import { Component, OnInit } from "@angular/core";
import { NgbDropdownConfig } from "@ng-bootstrap/ng-bootstrap";
import { HumanResource } from "src/app/ms/hr-ms/model/HumanResource";

@Component({
  selector: "app-nav-right",
  templateUrl: "./nav-right.component.html",
  styleUrls: ["./nav-right.component.scss"],
  providers: [NgbDropdownConfig],
})
export class NavRightComponent implements OnInit {
  public currentUser: HumanResource;
  constructor() {}

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem("user-data"));
  }
}
