import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MissionService } from "src/app/ms/mission-ms/service/mission.service";
import { Mission } from "src/app/ms/mission-ms/model/Mission";
import { Client } from "src/app/ms/mission-ms/model/Client";
import { ClientContact } from "src/app/ms/mission-ms/model/ClientContact";
import { Status } from "src/app/ms/mission-ms/model/Status";
import { TranslateService } from "@ngx-translate/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { StatusLabel } from "src/app/ms/mission-ms/model/StatusLabel";
import { StatusService } from "src/app/ms/mission-ms/service/status.service";
import { HumanResourceService } from "src/app/ms/hr-ms/service/human-resource.service";
import { HUMAN_RESOURCE_PROFILES } from "src/app/utils/constants";
import { Consultant } from "src/app/ms/mission-ms/model/Consultant";
import { HumanResource } from "src/app/ms/hr-ms/model/HumanResource";
import { ConsultantService } from "src/app/ms/mission-ms/service/consultant.service";
import { forkJoin } from "rxjs";
import Swal from "sweetalert2";

//z
@Component({
  selector: "app-mission-detail",
  templateUrl: "./mission-detail.component.html",
  styleUrls: ["./mission-detail.component.scss"],
})
export class MissionDetailComponent implements OnInit {
  statut = [
    { id: 1, name: "en cours" },
    { id: 2, name: "suspendue" },
    { id: 3, name: "non débutée" },
    { id: 4, name: "débutée" },
    { id: 5, name: "annulée" },
  ];
  public showConsultants: boolean = false;
  selectedStatusLabel: StatusLabel;
  selectedManager: number;
  public frm: FormGroup;
  public consultantsList: Consultant[] = [];
  covertedConsultants: Consultant[] = [];
  selectedCityIds: string[];
  selectedConsultants: any[] = [];
  public basicContent: string;
  public showConsultantsSelect: boolean;
  public editMission: boolean;
  public editMissionIcon: string;

  public editClient: boolean;
  public editClientIcon: string;

  public editClientContact: boolean;
  public editClientContactIcon: string;

  public editConsultants: boolean;
  public editConsultantsIcon: string;

  public liste_status = ["EN_COURS", "SUSPENDUE", "TERMINEE"];
  public liste_gender = ["Male", "Female"];
  consultantsF: any[];
  public name: string;
  public status_label: StatusLabel;
  public startDate: Date;
  public endDate: Date;
  company_entity_id: any;
  managersList:any
  managersMap: Map<number, String> = new Map<number, String>();
  status: Status;
  public client: Client;
  public status_obj: Status;
  public contact_client: ClientContact;
  public mission_update: Mission;

  public mission_obj: Mission;
  user: HumanResource;
  today:Date;

  error: any = { isError: false, errorMessage: "" };
  error_client: any = { isError: false, errorMessage: "" };
  error_client_contact: any = { isError: false, errorMessage: "" };
  statusList: Status[];
  selectedStatus: Status;

  @Input() mission:Mission;
  @Input() showView;
  @Output() event = new EventEmitter();

  constructor(public missionService: MissionService,
    private statusService: StatusService,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private humanResourceService: HumanResourceService,
    private consultantService:ConsultantService
  ) {
    translate.setDefaultLang(localStorage.getItem("language"));
    this.editMission = false;
    this.editMissionIcon = "icon-edit";
    this.showConsultantsSelect = false;
  }

  compareTwoDates() {
    if (new Date(this.startDate) > new Date(this.endDate)) {  
      this.error = {
        isError: true,
        errorMessage: this.translate.instant("missions-module.error-date"),
      };
    } else
      this.error = {
        isError: false,
        errorMessage: "",
      };
  }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem("user-data"));
    this.company_entity_id = this.user
      ? Number(this.user?.companyEntity?.id)
      : null;
    if(this.user?.role != 'Client_Contact' ){
      this.getManagersMap();
      this.getConsultantsList();
    }
    this.name = this.mission.name;
    this.startDate = this.mission.startDate;
    this.endDate = this.mission.endDate;
    this.status_label = this.mission.status.label;
    this.selectedManager = this.mission.managerId;
    this.frm = this.formBuilder.group({
      id: [this.mission.id],
      name: [this.mission.name],
      startDate: [this.mission.startDate],
      endDate: [this.mission.endDate],
      client: [this.mission.client],
      clientId: [this.mission.client.id],
      managerId: [this.mission.managerId],
      status: [""],
      clientContacts: [this.mission.clientContacts],
      consultants: [this.mission.consultants],
      hrs:[this.selectedConsultants]
    });
  }

  getConsultantsList() {
    return this.humanResourceService
      .getConsultantsByCompanyEntityId(this.company_entity_id)
      .subscribe(
        (data) => {
          const filteredConsultants = this.filterHrOfTypeConsultant(data);
          this.consultantsList = filteredConsultants.filter((consultant) =>
            !this.mission.consultants.some((c) => c.hrId == consultant.id)
          );
          this.consultantsList = this.consultantsList.map((i) => {
            i.fullName = i.firstname + " " + i.lastname;
            return i;
          });
        },
        (error) => {
        }
      );
  }

  filterHrOfTypeConsultant(humanResources) {
    return humanResources.filter((x) => x.role == HUMAN_RESOURCE_PROFILES.CONSULTANT);
  }

  addSelectedConsultant(event: any): void {
    event.forEach((e: any) => {
      if (!this.selectedConsultants.some((consultant: any) => consultant.id === e.id)) {
        this.selectedConsultants.push(e);
      }
    });
    }
  

    update() {
      this.today = new Date();
      if ((this.today > new Date(this.frm.controls.startDate.value)) && (this.today < new Date(this.frm.controls.endDate.value))) {
        this.statusService.getStatusByCode("4").subscribe((status: any) => {
          this.frm.controls.status.setValue(status);
          Swal.fire({
            text: this.translate.instant("WARNING_API_UPDATE_MISSION"),
            type: "warning",
            showCloseButton: true,
            showCancelButton: true,
          }).then((willDelete) => {
            if (willDelete.dismiss) {
              Swal.fire("", this.translate.instant("TEXT_UPDATE_CANCELLED"), "error");
            } else {
              this.missionService.updateMission(this.frm.value).subscribe(
                (data) => {
                  Swal.fire(
                    "",
                    this.translate.instant("TEXT_MISSION_UPDATE_SUCESS"),
                    "success"
                  );
                
                  this.mission.name=this.frm.get('name').value;
                  this.mission.startDate=this.frm.get('startDate').value;
                  this.mission.endDate=this.frm.get('endDate').value;
                  this.editMission = false; 
                  this.editMissionIcon = 'icon-edit'; 
                  this.showConsultantsSelect = false;
                },
                (error) => {
                  Swal.fire(
                    "",
                    this.translate.instant("ERROR_API_UPDATE_MISSION"),
                    "error"
                  );
                }
              );
            }
          });
        });
      } else {
        this.statusService.getStatusByCode("6").subscribe((status: any) => {
          this.frm.controls.status.setValue(status);
          Swal.fire({
            text: this.translate.instant("WARNING_API_UPDATE_MISSION"),
            type: "warning",
            showCloseButton: true,
            showCancelButton: true,
          }).then((willDelete) => {
            if (willDelete.dismiss) {
              Swal.fire("", this.translate.instant("TEXT_UPDATE_CANCELLED"), "error");
              
            } else {
              this.missionService.updateMission(this.frm.value).subscribe(
                (data) => {
                  Swal.fire(
                    "",
                    this.translate.instant("TEXT_MISSION_UPDATE_SUCESS"),
                    "success"
                  );
                  this.editMission = false; 
                  this.editMissionIcon = 'icon-edit'; 
                  this.showConsultantsSelect = false;
                },
                (error) => {
                  Swal.fire(
                    "",
                    this.translate.instant("ERROR_API_UPDATE_MISSION"),
                    "error"
                  );
                }
              );
            }
          });
        });
      }
    }
    

  getManagersMap() {
    return this.humanResourceService
      .getManagersByCompanyId(this.user.companyEntity.company.id)
      .subscribe(
        (data) => {
          this.managersList = this.filterHrOfTypeManager(data);
          this.managersList.map((i) => {
            i.fullname = i.firstname + " " + i.lastname;
            this.managersMap.set(Number(i.id), i.fullname);
          });
        },
        (error) => {

        }
    );
  }
 
  filterHrOfTypeManager(humanResources) {
    return humanResources.filter((x) => x.role == HUMAN_RESOURCE_PROFILES.BUSINESS_MANAGER || HUMAN_RESOURCE_PROFILES.COMPANY_ADMIN);
  }


  getStatusText(status: string): string {
    let statusText: string;

    switch (status) {
      case 'EN_COURS':
        statusText = this.translate.instant("TEXT_STATUS_EN_COURS");
        break;
      case 'TERMINEE':
        statusText = this.translate.instant("TEXT_STATUS_FINISHED");
        break;
      case 'SUSPENDUE':
        statusText = this.translate.instant("TEXT_STATUS_SUSPENDUE");
        break;
      default:
        statusText = '';
        break;
    }

    return statusText;
  }

  toggleConsultantDetails(): void {
    this.showConsultants = !this.showConsultants;
  }

  removeConsultant(consultantId: bigint):void {
    const index = this.mission.consultants.findIndex(consultant => consultant.id === consultantId);
    if (index !== -1) {
      this.mission.consultants.splice(index, 1);
      this.frm.get('consultants').updateValueAndValidity();
      this.consultantService.removeConsultant(consultantId).subscribe((resp)=>{},(error)=>{})
    }
  }
  
 
}
