import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { CompanyEntity } from "../model/CompanyEntity";
import { Observable } from "rxjs";
import { Company } from "../model/Company";

@Injectable({
  providedIn: "root",
})
export class CompanyEntityService {
  baseUrl: string = "/hr-ms/api/v1/company-entities";

  constructor(private http: HttpClient) { }
  getCompanyById(id: bigint): Observable<any> {
    return this.http.get<Company>(`${this.baseUrl}/${id}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }
  addCompanyEntity(companyEntity: CompanyEntity): Observable<CompanyEntity> {
    return this.http.post<CompanyEntity>(this.baseUrl, companyEntity);
  }
  getCompanyEntityByCompany(id: bigint): Observable<any> {
    return this.http.get(this.baseUrl + "/by-company?id=" + id);
  }
  deleteCompanyEntity(id) {
    return this.http.delete(this.baseUrl + "/" + id);
  }
  updatecompanyEntity(id: any,companyEntity:CompanyEntity) {
    return this.http.put(this.baseUrl+ "/" + id,companyEntity);
  }
  getAllCompaniesEntity():Observable<any>{
    return this.http.get(this.baseUrl);
  }
}
