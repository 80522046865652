import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { NextConfig } from "../../../../app-config";

@Component({
  selector: "app-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.scss"],
})
export class NavigationComponent implements OnInit {
  public windowWidth: number;
  public nextConfig: any;
  @Output() onNavMobCollapse = new EventEmitter();

  langue: string;

  constructor() {
    this.nextConfig = NextConfig.config;
    this.windowWidth = window.innerWidth;
  }

  ngOnInit() {
    this.langue = localStorage.getItem("language");
  }

  navMobCollapse() {
    if (this.windowWidth < 992) {
      this.onNavMobCollapse.emit();
    }
  }

  useLanguage(language: string) {
    localStorage.setItem("language", language);
    window.location.reload();
  }
}
