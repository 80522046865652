import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";

import { User } from "../ms/oauth2/model/User";
import { AuthenticationService } from "../ms/oauth2/service/authentication.service";
import { HUMAN_RESOURCE_ROLES } from "../utils/constants";

@Injectable({
  providedIn: "root",
})
export class AuthorizationGuard implements CanActivate, CanActivateChild {
  /**
   *
   */
  constructor(
    public router: Router,
    private authenticationService: AuthenticationService
  ) { }

  /**
   *
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // this will be passed from the route config
    // on the data property

    if (this.authenticationService.currentUserValue) {
      if (!this.authenticationService.isExpired()) {
        return this.checkAuth(
          this.authenticationService.currentUserValue,
          route
        );
      } else {
        this.authenticationService.refreshToken().subscribe(
          (data) => {
            window.location.reload();
          },
          (error) => {

          }
        );
      }
    } else {
      // not logged in so redirect to login page with the return url
      //this.router.navigate(["/login"], {queryParams: { returnUrl: state.url },});

      if (location.pathname.includes("/cooptations/profile") || location.pathname.includes("/requirements/need")) {
        localStorage.setItem("redirectTo", location.pathname)
      }


      this.router.navigate(["/login"]);
      return false;
    }
  }

  currentUserIsAuthorized(currentUser: User, route: ActivatedRouteSnapshot) {
    let necessaryRoles = route.data.roles;
    let params = route.params;
    let isNotConsultantOrHasSameId = Object.keys(params).length === 0 || currentUser.roles.length > 1 || currentUser.roles.includes(HUMAN_RESOURCE_ROLES.CONSULTANT) || currentUser.roles.includes(HUMAN_RESOURCE_ROLES.BUSINESS_MANAGER) || currentUser.roles.includes(HUMAN_RESOURCE_ROLES.COMPANY_ADMIN) || currentUser.roles.includes(HUMAN_RESOURCE_ROLES.HUMAN_RESOURCE) || params.id == currentUser.sub || params.hrId == currentUser.sub;

    return isNotConsultantOrHasSameId && currentUser.roles.some((r) => necessaryRoles?.indexOf(r) >= 0);
  }

  checkAuth(currentUser: User, route: ActivatedRouteSnapshot): boolean {
    // const expectedAuth = route.data.expectedAuth;
    // check if route is restricted by role

    if (
      route.data &&
      route.data.roles &&
      currentUser &&
      currentUser.roles &&
      !this.currentUserIsAuthorized(currentUser, route)
    ) {
      // role not authorized so redirect to home page
      this.router.navigate(["/dashboard"]);
      //this.router.navigate(['/login'], {queryParams: {returnUrl: (route ? route.url : '')}}).then();
      //this.router.navigate(['/error/403']).then(_ => Promise.resolve());
      return false;
    } else {
      // authorized so return true
      return true;
    }
  }

  /**
   *
   */
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(route, state);
  }
}
