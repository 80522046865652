import { Component, Input, OnInit } from "@angular/core";
import ApexCharts from "apexcharts/dist/apexcharts.common.js";
import { ApexChartService } from "./apex-chart.service";

@Component({
  selector: "app-apex-chart",
  templateUrl: "./apex-chart.component.html",
  styleUrls: ["./apex-chart.component.scss"],
})
export class ApexChartComponent implements OnInit {
  @Input() chartID: string;
  @Input() chartConfig: any;
  @Input() xAxis: any;
  @Input() newData: any;

  public chart: any;

  constructor(private apexEvent: ApexChartService) {}

  ngOnInit() {

    setTimeout(() => {
      this.chart = new ApexCharts(
        document.querySelector("#" + this.chartID),
        this.chartConfig
      );
      this.chart.render();
    });

    this.apexEvent.changeTimeRange.subscribe(() => {
      if (this.xAxis) {
        this.chart.updateOptions({
          xaxis: this.xAxis,
        });
      }
    });

    this.apexEvent.changeSeriesData.subscribe(() => {
      if (this.newData) {
        this.chart.updateSeries([
          {
            data: this.newData,
          },
        ]);
      }
    });
  }
}
